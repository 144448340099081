import React, {useState } from "react"
import Select from 'react-select';

const NewsCategories = ({ newsCategory }) => {

const newsItems = []
const filterTitles = [{value: "Filter by service",label: "Filter by service"}]
const newNewsItems=[]
const newsCats=[{value: "Filter by category",label: "Filter by category"}]

newsCategory.forEach(function(content) {
  content.posts.nodes.map(prod =>
      newsItems.push({
        slug: prod.slug,
        name: prod.serviceCategories.nodes[0].name,
        title: prod.title,
        category: prod.categories.nodes[0].name,
        date: prod.date
      })
    )

    content.posts.nodes.map(prod =>
        filterTitles.push({
          value: prod.serviceCategories.nodes[0].name,
          label: prod.serviceCategories.nodes[0].name
        })
    )

    content.posts.nodes.map(prod =>
        newsCats.push({
          value: prod.categories.nodes[0].name,
          label: prod.categories.nodes[0].name
        })
      )
});

newsItems.sort(function(a, b) {
    var dateA = new Date(a.date), dateB = new Date(b.date);
    return dateB - dateA;
});

function multiDimensionalUnique(arr) {
    var uniques = [];
    var itemsFound = {};
    for(var i = 0, l = arr.length; i < l; i++) {
        var stringified = JSON.stringify(arr[i]);
        if(itemsFound[stringified]) { continue; }
        uniques.push(arr[i]);
        itemsFound[stringified] = true;
    }
    return uniques;
}

const uniqueTitles=multiDimensionalUnique(filterTitles);
const uniqueCats=multiDimensionalUnique(newsCats);

//console.log("newscats"+newsCats)
//console.log("WTF2"+multiDimensionalUnique(filterTitles))

  const [selectedOption, setSelectedOption] = useState()
  const [newsItemObjects, setNewsItemObjects] = useState(newsItems)
  const [categoriesSelected, categoriesSetSelected] = useState()
  const [catsSelected, catsSetSelected] = useState()
  const [loadMoreNews,setLoadMoreNews] = useState(true)

  const loadMore=()=>{
    setLoadMoreNews(false);
    var elems = document.querySelectorAll(".hide");
      [].forEach.call(elems, function(el) {
          el.className = el.className.replace(/\bhide\b/, "not-hidden");
      });
  }
  const loadLess=()=>{

    setLoadMoreNews(true);
    var elems = document.querySelectorAll(".not-hidden");
      [].forEach.call(elems, function(el) {
          el.className = el.className+" hide";
      });
  }
  const handleSelect=(e)=>{
    catsSetSelected(e.label)
    if(e.label==="Filter by service"){
      return false;
    }
    else{
      if(categoriesSelected){
        newsItems.map(items => items.name === e.label && items.category === categoriesSelected ? (
            newNewsItems.push({
              slug: items.slug,
              name: items.name,
              title: items.title,
              category:items.category,
              date: items.date
            })
            ) : (
              null
            )
          )
      }
      else{
        newsItems.map(items => items.name === e.label ? (
            newNewsItems.push({
              slug: items.slug,
              name: items.name,
              title: items.title,
              category:items.category,
              date: items.date
            })
            ) : (
              null
            )
          )
      }

    }
    setNewsItemObjects(newNewsItems)


  }

  const handleSelectCats=(e)=>{
    categoriesSetSelected(e.label)

    if(e.label==="Filter by category"){
      return false;
    }
    else{
      if(catsSelected){
        newsItems.map(items => items.category === e.label && items.name === catsSelected ? (
            newNewsItems.push({
              slug: items.slug,
              name: items.name,
              title: items.title,
              category:items.category,
              date: items.date
            })
            ) : (
              null
            )
          )
      }
      else{
        newsItems.map(items => items.category === e.label ? (
            newNewsItems.push({
              slug: items.slug,
              name: items.name,
              title: items.title,
              category:items.category,
              date: items.date
            })
            ) : (
              null
            )
          )
      }


    }
    setNewsItemObjects(newNewsItems)
  }

  const customStyles = {
    option: (provided,selectedOption) => ({
      ...provided,
      color: "#666",
      backgroundColor: '#fff',
      fontSize: '22px',
      fontWeight: '200',
    }),
    control: (provided,selectedOption) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '0px',
      borderRadius: '30px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#666",
      backgroundColor: '#fff',
      fontSize: '22px',
      fontWeight: '200',
    }),
  }

  return (
    <>
    <div className="eighty-spacer"></div>
    <section className="content navy-background">
<div className="content-block news-dropdown-content-block clearfix">
<div className="filter-block-container clearfix">
<div className="filter-block clearfix">
  <div className="cta-help-field-holder">
    <div className="news-select-dropdown-holder">
      <form>
        <Select styles = { customStyles } options={uniqueCats} defaultValue={uniqueCats[0]} onChange={handleSelectCats} multi />
        </form>
    </div>
  </div>
  <div className="cta-help-field-holder">
    <div className="news-service-select-dropdown-holder">
      <form>
        <Select styles = { customStyles } options={uniqueTitles} defaultValue={uniqueTitles[0]} onChange={handleSelect} multi />
        </form>
    </div>
  </div>
</div>
</div>
</div>
</section>
    <section className="content scroll-to-filter very-light-blue-background">
      <div className="eighty-spacer"></div>
      <div className="content-block">
        <div className="one-flex-container">
          <ul className="news-insights">
            {newsItemObjects.length
              ? newsItemObjects.map((prod,index) => (
                <>
                  {index <=17 ?
                  <li className={`mix third-flex-container news-insights-page-container latest-news tech ${index >= 9 ? "hide" : ""}`} key={index}>
                    <a href={`/blog/${prod.slug}`} className="scaleDiv">
                      <div className="news-insights-content-container navy-background">
                        <div className="news-insights-content-holder">
                          <div className="news-insights-title-holder">
                              <h4 className={prod.name}>{prod.name}</h4>
                          </div>
                          <div className="news-insights-text-holder">
                            <h6 dangerouslySetInnerHTML={{ __html: prod.title }}/>
                            <div className="full-width-news-content-container"></div>
                          </div>
                            <div className="news-insights-link-holder">
                              Read more
                            </div>
                        </div>
                      </div>
                      <div className="news-insights-image-holder">
                        <img
                          src="/htbcontent/themes/htb-personal/assets/img/htb-news-backup.png"
                          alt="HTB offers Savings Products on the Insignis Cash Platform - News & Insights - Hampshire Trust Bank (HTB)"
                          title="HTB offers Savings Products on the Insignis Cash Platform - News & Insights - Hampshire Trust Bank (HTB)"
                          width="360px"
                          height="200px"
                        />
                      </div>
                    </a>
                  </li>
                  :null}
                  </>
                ))
              : <p>No articles</p>}
          </ul>
          <div className="loadmore">
          {loadMoreNews ?
          <button onClick={loadMore} className="loadmore-button">Load more {loadMoreNews}</button>
          :
          <button onClick={loadLess} className="loadmore">Reset {loadMoreNews}</button>
          }
        </div>
        </div>
      </div>
      <div className="eighty-spacer"></div>
    </section>
    </>
  )
}

export default NewsCategories
